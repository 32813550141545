.progress-circle {
  width: 230px;
  height: 230px;
  line-height: 230px;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
}
.progress-circle:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 12px solid #f0f0f0;
  position: absolute;
  top: 0;
  left: 0;
}
.progress-circle > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}
.progress-circle .progress-circle-left {
  left: 0;
}
.progress-circle .progress-circle-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 12px;
  border-style: solid;
  position: absolute;
  top: 0;
}
.progress-circle .progress-circle-left .progress-circle-bar {
  left: 100%;
  border-top-right-radius: 120px;
  border-bottom-right-radius: 120px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}
.progress-circle .progress-circle-right {
  right: 0;
}
.progress-circle .progress-circle-right .progress-circle-bar {
  left: -100%;
  border-top-left-radius: 120px;
  border-bottom-left-radius: 120px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
  animation: loading-1 1.8s linear forwards;
}
.progress-circle .progress-circle-value {
  width: 90%;
  height: 90%;
  border-radius: 50%;
  background: #ffffff;
  font-size: 45px;
  font-weight: 400;
  color: #22153d;
  line-height: 200px;
  text-align: center;
  position: absolute;
  top: 5%;
  left: 5%;
}
.progress-circle.green .progress-circle-bar {
  border-color: #32c388;
}
.progress-circle.blue .progress-circle-left .progress-circle-bar {
  animation: loading-2 1.5s linear forwards 1.8s;
}
.progress-circle.yellow .progress-circle-bar {
  border-color: #fdba04;
}
.progress-circle.yellow .progress-circle-left .progress-circle-bar {
  animation: loading-3 1s linear forwards 1.8s;
}
.progress-circle.pink .progress-circle-bar {
  border-color: #ed687c;
}
.progress-circle.pink .progress-circle-left .progress-circle-bar {
  animation: loading-4 0.4s linear forwards 1.8s;
}
.progress-circle.green .progress-circle-bar {
  border-color: #1abc9c;
}
.progress-circle.green .progress-circle-left .progress-circle-bar {
  animation: loading-0 1.2s linear forwards 1.8s;
}
@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(144deg);
    transform: rotate(144deg);
  }
}
@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}
@keyframes loading-4 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(36deg);
    transform: rotate(36deg);
  }
}
@keyframes loading-5 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(126deg);
    transform: rotate(126deg);
  }
}
@media only screen and (max-width: 990px) {
  .progress-circle {
    margin-bottom: 20px;
  }
}
