.preloader.pls-teal .plc-path {
  stroke: #009688;
}

.preloader.pls-gray .plc-path {
  stroke: #9e9e9e;
}

.preloader.pls-pink .plc-path {
  stroke: #e91e63;
}

.preloader.pls-purple .plc-path {
  stroke: #9c27b0;
}

.preloader.pls-white .plc-path {
  stroke: #fff;
}

.pl-circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.plc-path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke-width: 2;
  stroke-miterlimit: 10;
  fill: none;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes color {
  100%,
  0% {
    stroke: #f44336;
  }

  40% {
    stroke: #2196f3;
  }

  66% {
    stroke: #4caf50;
  }

  80%,
  90% {
    stroke: #ffc107;
  }
}

[data-ma-header="teal"]:before,
[data-ma-header="teal"] #header {
  background-color: #009688;
}

[data-ma-header="teal"] .smm-alerts > li.active,
[data-ma-header="teal"] .main-menu li.active > a {
  color: #009688;
}

[data-ma-header="blue"]:before,
[data-ma-header="blue"] #header {
  background-color: #2196f3;
}

[data-ma-header="blue"] .smm-alerts > li.active,
[data-ma-header="blue"] .main-menu li.active > a {
  color: #2196f3;
}

[data-ma-header="bluegrey"]:before,
[data-ma-header="bluegrey"] #header {
  background-color: #607d8b;
}

[data-ma-header="bluegrey"] .smm-alerts > li.active,
[data-ma-header="bluegrey"] .main-menu li.active > a {
  color: #607d8b;
}

[data-ma-header="cyan-600"]:before,
[data-ma-header="cyan-600"] #header {
  background-color: #00acc1;
}

[data-ma-header="cyan-600"] .smm-alerts > li.active,
[data-ma-header="cyan-600"] .main-menu li.active > a {
  color: #00acc1;
}

[data-ma-header="green"]:before,
[data-ma-header="green"] #header {
  background-color: #4caf50;
}

[data-ma-header="green"] .smm-alerts > li.active,
[data-ma-header="green"] .main-menu li.active > a {
  color: #4caf50;
}

[data-ma-header="lightgreen"]:before,
[data-ma-header="lightgreen"] #header {
  background-color: #8bc34a;
}

[data-ma-header="lightgreen"] .smm-alerts > li.active,
[data-ma-header="lightgreen"] .main-menu li.active > a {
  color: #8bc34a;
}

[data-ma-header="purple-400"]:before,
[data-ma-header="purple-400"] #header {
  background-color: #ab47bc;
}

[data-ma-header="purple-400"] .smm-alerts > li.active,
[data-ma-header="purple-400"] .main-menu li.active > a {
  color: #ab47bc;
}

[data-ma-header="red-400"]:before,
[data-ma-header="red-400"] #header {
  background-color: #ef5350;
}

[data-ma-header="red-400"] .smm-alerts > li.active,
[data-ma-header="red-400"] .main-menu li.active > a {
  color: #ef5350;
}

[data-ma-header="pink-400"]:before,
[data-ma-header="pink-400"] #header {
  background-color: #ec407a;
}

[data-ma-header="pink-400"] .smm-alerts > li.active,
[data-ma-header="pink-400"] .main-menu li.active > a {
  color: #ec407a;
}

[data-ma-header="brown"]:before,
[data-ma-header="brown"] #header {
  background-color: #795548;
}

[data-ma-header="brown"] .smm-alerts > li.active,
[data-ma-header="brown"] .main-menu li.active > a {
  color: #795548;
}

[data-ma-header="grey-600"]:before,
[data-ma-header="grey-600"] #header {
  background-color: #757575;
}

[data-ma-header="grey-600"] .smm-alerts > li.active,
[data-ma-header="grey-600"] .main-menu li.active > a {
  color: #757575;
}

[data-ma-header="orange"]:before,
[data-ma-header="orange"] #header {
  background-color: #ff9800;
}

[data-ma-header="orange"] .smm-alerts > li.active,
[data-ma-header="orange"] .main-menu li.active > a {
  color: #ff9800;
}

a.thumbnail:hover,
a.thumbnail:focus,
a.thumbnail.active {
  border-color: #e0e0e0;
  box-shadow: 0 0 6px #eaeaea;
}

.thumbnail .caption {
  padding: 10px 20px 15px;
}

@media screen and (min-width: 1500px) {
  #c-grid[data-columns]::before {
    content: "3 .column.size-1of3";
  }
}

@media screen and (min-width: 768px) {
  #c-grid {
    margin: 0 -15px;
  }

  .column {
    padding: 0 15px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1499px) {
  #c-grid[data-columns]::before {
    content: "2 .column.size-1of2";
  }
}

@media screen and (max-width: 767px) {
  #c-grid {
    margin: 0 -10px;
  }

  .column {
    padding: 0 10px;
  }

  #c-grid[data-columns]::before {
    content: "1 .column.size-1of1";
  }
}

.column {
  float: left;
}

.size-1of1 {
  width: 100%;
}

.size-1of2 {
  width: 50%;
}

.size-1of3 {
  width: 33.333%;
}

@media print {
  @page {
    margin: 0;
    size: auto;
  }

  body {
    margin: 0mm 0mm 0mm 0mm !important;
    padding: 0mm !important;
  }

  #header,
  .c-header,
  #footer,
  #s-user-alerts,
  #s-main-menu,
  #chat,
  .growl-animated,
  .m-btn {
    display: none !important;
  }

  /*
     * INVOICE
     */

  .invoice {
    padding: 30px !important;
    print-color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
  }

  .invoice .card-header {
    background: #eee !important;
    padding: 20px;
    margin-bottom: 20px;
    margin: -10px -30px 25px -30px;
  }

  .invoice .block-header {
    display: none;
  }

  .invoice .highlight {
    background: #eee !important;
  }
}

/*
 * Vendor Overrides
 */

#fc-actions {
  position: absolute;
  bottom: 10px;
  right: 12px;
}

.fc td,
.fc th {
  border-color: transparent;
}

.fc th {
  font-weight: 400;
  padding: 5px 0;
}

.fc table {
  background: transparent;
}

.fc table tr > td:first-child {
  border-left-width: 0;
}

#calendar-widget .fc-toolbar h2 {
  font-size: 16px;
}

#calendar-widget .fc-day-number {
  text-align: center;
  color: #000;
}

#calendar-widget .fc-day-grid-event {
  margin: 1px 3px 1px;
}

#calendar-widget .ui-widget-header th,
#calendar-widget .ui-widget-header {
  border-width: 0;
}

#calendar .fc-toolbar {
  height: 300px;

  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  background-position: inherit;
}

#calendar .fc-toolbar h2 {
  font-size: 20px;
  color: #fff;
}

#calendar .fc-toolbar:before {
  content: "";
  height: 50px;
  width: 100%;
  background: rgba(0, 0, 0, 0.36);
  position: absolute;
  bottom: 0;
  left: 0;
}

#calendar .fc-toolbar .fc-center {
  margin-top: 238px;
  position: relative;
}

#calendar .fc-toolbar .ui-button {
  margin-top: 3px;
}

#calendar .fc-toolbar .ui-button span {
  color: #fff;
}

#calendar .fc-toolbar .ui-button:hover span {
  color: #333;
}

@media screen and (max-width: 991px) {
  #calendar .fc-toolbar {
    height: 200px;
  }

  #calendar .fc-toolbar .fc-center {
    margin-top: 138px;
  }
}

#calendar .fc-day-number {
  padding-left: 10px !important;
  color: #ccc;
  text-align: left !important;
}

@media screen and (min-width: 991px) {
  #calendar .fc-day-number {
    font-size: 25px;
    letter-spacing: -2px;
  }
}

#calendar .fc-day-header {
  color: #333 !important;
  text-align: left !important;
}

#calendar .fc-day-grid-event {
  margin: 1px 9px 0;
}

.fc-day-header {
  font-size: 0;
  padding-bottom: 20px !important;
}

.fc-day-header:first-letter {
  font-size: 14px;
  color: #c2c2c2;
  text-align: left;
  border-bottom-width: 0;
  border-right-color: #eee;
  padding: 10px 12px;
  text-transform: uppercase;
  font-weight: 700;
}

.fc-today {
  color: #ffc107;
}

.fc-toolbar {
  margin-bottom: 0;
  padding: 20px 17px 19px;
  position: relative;
}

.fc-toolbar h2 {
  margin-top: 7px;
}

.fc-toolbar .ui-button {
  border: 0;
  background: 0 0;
  padding: 0;
  outline: none !important;
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.fc-toolbar .ui-button:hover {
  background: #efefef;
}

.fc-toolbar .ui-button > span {
  position: relative;
  font-family: "Material-Design-Iconic-Font";
  font-size: 24px;
  line-height: 100%;
  width: 30px;
  display: block;
  margin-top: 2px;
}

.fc-toolbar .ui-button > span:before {
  position: relative;
  z-index: 1;
}

.fc-toolbar .ui-button > span.ui-icon-circle-triangle-w:before {
  content: "\f2fa";
}

.fc-toolbar .ui-button > span.ui-icon-circle-triangle-e:before {
  content: "\f2fb";
}

.fc-event {
  padding: 0;
  font-size: 11px;
  border-radius: 2px;
  border: 0;
}

.fc-event .fc-title {
  padding: 2px 8px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fc-event .fc-time {
  float: left;
  background: rgba(0, 0, 0, 0.2);
  padding: 2px 6px;
  margin: 0 0 0 -1px;
}

.fc-view,
.fc-view > table {
  border: 0;
  overflow: hidden;
}

.fc-view > table > tbody > tr > .ui-widget-content {
  border-top: 0;
}

div.fc-row {
  margin-right: 0 !important;
  border: 0 !important;
}

.fc-today {
  color: #ffc107 !important;
}

/* Even Tag Color */

.event-tag {
  margin-top: 5px;
}

.event-tag > span {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 3px;
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.event-tag > span:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.event-tag > span.selected:before {
  font-family: "Material-Design-Iconic-Font";
  content: "\f26b";
  position: absolute;
  text-align: center;
  top: 3px;
  width: 100%;
  font-size: 17px;
  color: #fff;
}

hr.fc-divider {
  border-width: 1px;
  border-color: #eee;
}

.fc-day-grid-container.fc-scroller {
  height: auto !important;
  overflow: hidden !important;
}

/* Even Tag Color */

.event-tag {
  margin-top: 5px;
}

.event-tag > span {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 3px;
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.event-tag > span:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.event-tag > span.selected:before {
  font-family: "Material-Design-Iconic-Font";
  content: "\f26b";
  position: absolute;
  text-align: center;
  top: 3px;
  width: 100%;
  font-size: 17px;
  color: #fff;
}

.bootgrid-footer .infoBar,
.bootgrid-header .actionBar {
  text-align: left;
}

.bootgrid-footer .search,
.bootgrid-header .search {
  vertical-align: top;
}

.bootgrid-header {
  padding: 0 25px 10px;
}

.bootgrid-header .search {
  border: 1px solid #e0e0e0;
}

.bootgrid-header .search .form-control,
.bootgrid-header .search .input-group-addon {
  border: 0;
}

.bootgrid-header .search .glyphicon-search {
  vertical-align: top;
  padding: 9px 10px 0;
}

.bootgrid-header .search .glyphicon-search:before {
  content: "\f1c3";
  font-family: "Material-Design-Iconic-Font";
  font-size: 17px;
  vertical-align: top;
  line-height: 100%;
}

@media (min-width: 480px) {
  .bootgrid-header .search {
    width: 300px;
  }
}

@media (max-width: 480px) {
  .bootgrid-header .search {
    width: 100%;
    padding-right: 90px;
  }
}

.bootgrid-header .actions {
  box-shadow: none;
}

.bootgrid-header .actions .btn-group {
  border: 1px solid #e0e0e0;
}

.bootgrid-header .actions .btn-group .btn {
  height: 35px;
  box-shadow: none !important;
  background: transparent;
}

.bootgrid-header .actions .btn-group .dropdown-menu {
  padding: 10px 20px;
}

.bootgrid-header .actions .btn-group .dropdown-menu .dropdown-item {
  padding: 0 0 0 27px !important;
}

.bootgrid-header .actions .btn-group .dropdown-menu .dropdown-item:hover {
  background-color: #fff !important;
}

@media (min-width: 768px) {
  .bootgrid-header .actions .btn-group .dropdown-menu {
    left: 0;
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
    margin-top: 1px;
  }
}

.bootgrid-header .actions .btn-group .caret {
  display: none;
}

.bootgrid-header .actions .btn-group .zmdi {
  line-height: 100%;
  font-size: 18px;
  vertical-align: top;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}

.bootgrid-header .actions .btn-group.open .zmdi {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

@media (max-width: 480px) {
  .bootgrid-header .actions {
    position: absolute;
    top: 0;
    right: 15px;
  }
}

.bootgrid-table th > .column-header-anchor > .icon {
  top: 0px;
  font-size: 20px;
  line-height: 100%;
}

.bootgrid-footer .col-sm-6 {
  padding: 10px 30px 20px;
}

@media (max-width: 768px) {
  .bootgrid-footer .col-sm-6 {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .bootgrid-footer .infoBar {
    display: none;
  }
}

.bootgrid-footer .infoBar .infos {
  border: 1px solid #eee;
  float: right;
  padding: 7px 30px;
  font-size: 12px;
  margin-top: 5px;
}

.select-cell .checkbox {
  margin: 0;
}

.command-edit,
.command-delete {
  background: #fff;
}

.command-edit .zmdi,
.command-delete .zmdi {
  color: #6d6d6d;
}

.bootstrap-select .dropdown-toggle:focus {
  outline: none !important;
}

.bootstrap-select > .btn-default {
  background: none !important;
  border-bottom: 1px solid #e0e0e0 !important;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
}

.bootstrap-select .bs-searchbox {
  padding: 5px 5px 5px 40px;
  position: relative;
  background: #f7f7f7;
  margin-top: -10px;
}

.bootstrap-select .bs-searchbox:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 100%;
  content: "\f1c3";
  font-family: "Material-Design-Iconic-Font";
  font-size: 25px;
  padding: 4px 0 0 15px;
}

.bootstrap-select .bs-searchbox input {
  border: 0;
  background: transparent;
}

.bootstrap-select.btn-group .dropdown-menu li a.opt {
  padding-left: 17px;
}

.bootstrap-select .check-mark {
  margin-top: -5px !important;
  font-size: 19px;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  display: block !important;
  position: absolute;
  top: 11px;
  right: 15px;
}

.bootstrap-select .check-mark:before {
  content: "\f26b";
  font-family: "Material-Design-Iconic-Font";
}

.bootstrap-select .selected .check-mark {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.bootstrap-select .notify {
  bottom: 0 !important;
  margin: 0 !important;
  width: 100% !important;
  border: 0 !important;
  background: #f44336 !important;
  color: #fff !important;
  text-align: center;
}

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%;
}

.chosen-container .chosen-drop {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  margin-top: 1px;
  border: 0;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 300ms;
  animation-duration: 300ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  top: 0;
}

.chosen-container .chosen-results {
  margin: 0;
  padding: 0;
  max-height: 300px;
}

.chosen-container .chosen-results li {
  padding: 10px 17px;
  width: 100%;
}

.chosen-container .chosen-results li.highlighted {
  background: rgba(0, 0, 0, 0.075);
  color: #333333;
}

.chosen-container .chosen-results li.result-selected {
  background: transparent;
  color: #5e5e5e;
  position: relative;
}

.chosen-container .chosen-results li.result-selected:before {
  content: "\f26b";
  font-family: "Material-Design-Iconic-Font";
  position: absolute;
  right: 15px;
  top: 10px;
  font-size: 19px;
}

.chosen-container .chosen-results li.group-result {
  color: #b2b2b2;
  font-weight: normal;
  padding: 16px 15px 6px;
  margin-top: 9px;
}

.chosen-container .chosen-results li.group-result:not(:first-child) {
  border-top: 1px solid #eee;
}

.chosen-container-single .chosen-single {
  border-radius: 0;
  overflow: visible;
  height: 34px;
  padding: 6px 0 6px;
  text-transform: uppercase;
  border: 0;
  border-bottom: 1px solid #e0e0e0;
  background: none;
  box-shadow: none;
}

.chosen-container-single .chosen-single:before {
  background-repeat: no-repeat;
  background-position: center;
  width: 12px;
  height: 12px;
  content: "";
  position: absolute;
  right: 0;
  top: 10px;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .chosen-container-single .chosen-single:before {
    background-size: 12px 12px;
  }
}

.chosen-container-single .chosen-single div b {
  display: none;
}

.chosen-container-single .chosen-search {
  padding: 5px 5px 5px 40px;
  background: #f7f7f7;
}

.chosen-container-single .chosen-search:before {
  content: "\f1c3";
  font-family: "Material-Design-Iconic-Font";
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 100%;
  font-size: 25px;
  padding: 5px 0 0 15px;
}

.chosen-container-single .chosen-search input[type="text"] {
  border: 0;
  height: 35px;
  line-height: 1.42857143;
  background-image: none !important;
}

.chosen-container-active.chosen-with-drop .chosen-single {
  border: 0;
  background: none;
}

.chosen-container-multi .chosen-choices {
  padding: 0;
  border: 0;
  border-bottom: 1px solid #e0e0e0;
  background: none;
  box-shadow: none;
}

.chosen-container-multi .chosen-choices li.search-choice {
  border-radius: 2px;
  margin: 4px 4px 0 0;
  background: #eaeaea;
  padding: 5px 23px 5px 8px;
  border: 0;
  box-shadow: none;
}

.chosen-container-multi .chosen-choices li.search-field input[type="text"] {
  padding: 0;
  height: 31px;
}

.search-choice-close {
  cursor: pointer;
}

.search-choice-close:before {
  display: inline-block;
  font-family: "Material-Design-Iconic-Font";
  content: "\f135";
  position: relative;
  top: 1px;
  color: #9c9c9c;
  z-index: 2;
  background: #fff;
  font-size: 12px;
}

select.chosen {
  display: none;
}

.noUi-target {
  border-radius: 0;
  box-shadow: none;
  border: 0;
}

.noUi-background {
  background: #d4d4d4;
  box-shadow: none;
}

.noUi-horizontal {
  height: 3px;
}

.noUi-horizontal .noUi-handle {
  top: -8px;
}

.noUi-vertical {
  width: 3px;
}

.noUi-horizontal .noUi-handle,
.noUi-vertical .noUi-handle {
  width: 19px;
  height: 19px;
  border: 0;
  border-radius: 100%;
  box-shadow: none;
  -webkit-transition: box-shadow;
  -o-transition: box-shadow;
  transition: box-shadow;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  cursor: pointer;
  position: relative;
}

.noUi-horizontal .noUi-handle:before,
.noUi-vertical .noUi-handle:before,
.noUi-horizontal .noUi-handle:after,
.noUi-vertical .noUi-handle:after {
  display: none;
}

.noUi-horizontal .noUi-handle:active,
.noUi-vertical .noUi-handle:active {
  background: #ccc !important;
}

.noUi-horizontal .noUi-handle .is-tooltip,
.noUi-vertical .noUi-handle .is-tooltip {
  position: absolute;
  bottom: 32px;
  height: 35px;
  border-radius: 2px;
  color: #fff;
  text-align: center;
  line-height: 33px;
  width: 50px;
  left: 50%;
  margin-left: -25px;
  padding: 0 10px;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.noUi-horizontal .noUi-handle .is-tooltip:after,
.noUi-vertical .noUi-handle .is-tooltip:after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 10px 0 10px;
  position: absolute;
  bottom: -8px;
  left: 50%;
  margin-left: -9px;
  content: "";
}

.noUi-horizontal .noUi-active,
.noUi-vertical .noUi-active {
  box-shadow: 0 0 0 13px rgba(0, 0, 0, 0.1);
}

.noUi-horizontal .noUi-active .is-tooltip,
.noUi-vertical .noUi-active .is-tooltip {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  bottom: 40px;
  opacity: 1;
  filter: alpha(opacity=100);
}

.input-slider:not([data-is-color]) .noUi-handle,
.input-slider-range:not([data-is-color]) .noUi-handle,
.input-slider-values:not([data-is-color]) .noUi-handle,
.input-slider:not([data-is-color]) .noUi-connect,
.input-slider-range:not([data-is-color]) .noUi-connect,
.input-slider-values:not([data-is-color]) .noUi-connect {
  background: #009688 !important;
}

.input-slider:not([data-is-color]) .is-tooltip,
.input-slider-range:not([data-is-color]) .is-tooltip,
.input-slider-values:not([data-is-color]) .is-tooltip {
  background: #009688;
}

.input-slider:not([data-is-color]) .is-tooltip:after,
.input-slider-range:not([data-is-color]) .is-tooltip:after,
.input-slider-values:not([data-is-color]) .is-tooltip:after {
  border-color: #009688 transparent transparent transparent;
}

.input-slider[data-is-color="red"] .noUi-handle,
.input-slider-range[data-is-color="red"] .noUi-handle,
.input-slider-values[data-is-color="red"] .noUi-handle,
.input-slider[data-is-color="red"] .noUi-connect,
.input-slider-range[data-is-color="red"] .noUi-connect,
.input-slider-values[data-is-color="red"] .noUi-connect {
  background: #f44336 !important;
}

.input-slider[data-is-color="blue"] .noUi-handle,
.input-slider-range[data-is-color="blue"] .noUi-handle,
.input-slider-values[data-is-color="blue"] .noUi-handle,
.input-slider[data-is-color="blue"] .noUi-connect,
.input-slider-range[data-is-color="blue"] .noUi-connect,
.input-slider-values[data-is-color="blue"] .noUi-connect {
  background: #2196f3 !important;
}

.input-slider[data-is-color="cyan"] .noUi-handle,
.input-slider-range[data-is-color="cyan"] .noUi-handle,
.input-slider-values[data-is-color="cyan"] .noUi-handle,
.input-slider[data-is-color="cyan"] .noUi-connect,
.input-slider-range[data-is-color="cyan"] .noUi-connect,
.input-slider-values[data-is-color="cyan"] .noUi-connect {
  background: #00bcd4 !important;
}

.input-slider[data-is-color="amber"] .noUi-handle,
.input-slider-range[data-is-color="amber"] .noUi-handle,
.input-slider-values[data-is-color="amber"] .noUi-handle,
.input-slider[data-is-color="amber"] .noUi-connect,
.input-slider-range[data-is-color="amber"] .noUi-connect,
.input-slider-values[data-is-color="amber"] .noUi-connect {
  background: #ffc107 !important;
}

.input-slider[data-is-color="green"] .noUi-handle,
.input-slider-range[data-is-color="green"] .noUi-handle,
.input-slider-values[data-is-color="green"] .noUi-handle,
.input-slider[data-is-color="green"] .noUi-connect,
.input-slider-range[data-is-color="green"] .noUi-connect,
.input-slider-values[data-is-color="green"] .noUi-connect {
  background: #4caf50 !important;
}

.input-slider .noUi-origin {
  background: #d4d4d4;
}

.input-slider:not([data-is-color]) .noUi-base {
  background: #009688 !important;
}

.input-slider[data-is-color="red"] .noUi-base {
  background: #f44336 !important;
}

.input-slider[data-is-color="blue"] .noUi-base {
  background: #2196f3 !important;
}

.input-slider[data-is-color="cyan"] .noUi-base {
  background: #00bcd4 !important;
}

.input-slider[data-is-color="amber"] .noUi-base {
  background: #ffc107 !important;
}

.input-slider[data-is-color="green"] .noUi-base {
  background: #4caf50 !important;
}

.cp-container {
  position: relative;
}

.cp-container > .input-group input.cp-value {
  color: #000 !important;
  background: transparent !important;
}

.cp-container > .input-group .dropdown-menu {
  padding: 20px;
  margin-left: -1px;
}

.cp-container i.cp-value {
  width: 25px;
  height: 25px;
  border-radius: 2px;
  position: absolute;
  top: 0;
  right: 15px;
}

.note-editor .note-toolbar,
.note-popover .note-toolbar,
.note-editor .popover-content,
.note-popover .popover-content {
  background: #fff;
  border-color: #e4e4e4;
  margin: 0;
  padding: 10px 0 15px;
  text-align: center;
}

.note-editor .note-toolbar > .btn-group,
.note-popover .note-toolbar > .btn-group,
.note-editor .popover-content > .btn-group,
.note-popover .popover-content > .btn-group {
  display: inline-block;
  float: none;
  box-shadow: none;
}

.note-editor .note-toolbar > .btn-group .btn,
.note-popover .note-toolbar > .btn-group .btn,
.note-editor .popover-content > .btn-group .btn,
.note-popover .popover-content > .btn-group .btn {
  margin: 0 1px;
}

.note-editor .note-toolbar > .btn-group > .active,
.note-popover .note-toolbar > .btn-group > .active,
.note-editor .popover-content > .btn-group > .active,
.note-popover .popover-content > .btn-group > .active {
  background: #00bcd4;
  color: #fff;
}

.note-editor .note-toolbar .btn,
.note-popover .note-toolbar .btn,
.note-editor .popover-content .btn,
.note-popover .popover-content .btn {
  height: 40px;
  border-radius: 2px !important;
  box-shadow: none !important;
}

.note-editor .note-toolbar .btn:active,
.note-popover .note-toolbar .btn:active,
.note-editor .popover-content .btn:active,
.note-popover .popover-content .btn:active {
  box-shadow: none;
}

.note-editor .note-toolbar .note-palette-title,
.note-popover .note-toolbar .note-palette-title,
.note-editor .popover-content .note-palette-title,
.note-popover .popover-content .note-palette-title {
  margin: 0 !important;
  padding: 10px 0 !important;
  font-size: 13px !important;
  text-align: center !important;
  border: 0 !important;
}

.note-editor .note-toolbar .note-color-reset,
.note-popover .note-toolbar .note-color-reset,
.note-editor .popover-content .note-color-reset,
.note-popover .popover-content .note-color-reset {
  padding: 0 0 10px !important;
  margin: 0 !important;
  background: none;
  text-align: center;
}

.note-editor .note-toolbar .note-color .dropdown-menu,
.note-popover .note-toolbar .note-color .dropdown-menu,
.note-editor .popover-content .note-color .dropdown-menu,
.note-popover .popover-content .note-color .dropdown-menu {
  min-width: 335px;
}

.note-editor .note-statusbar .note-resizebar,
.note-popover .note-statusbar .note-resizebar {
  border-color: #e8e8e8;
}

.note-editor .note-statusbar .note-resizebar .note-icon-bar,
.note-popover .note-statusbar .note-resizebar .note-icon-bar {
  border-color: #bcbcbc;
}

.note-editor .fa,
.note-popover .fa {
  font-style: normal;
  font-size: 20px;
  vertical-align: middle;
}

.note-editor .fa:before,
.note-popover .fa:before {
  font-family: "Material-Design-Iconic-Font";
}

.note-editor .fa.fa-magic:before,
.note-popover .fa.fa-magic:before {
  content: "\f16a";
}

.note-editor .fa.fa-bold:before,
.note-popover .fa.fa-bold:before {
  content: "\f23d";
}

.note-editor .fa.fa-italic:before,
.note-popover .fa.fa-italic:before {
  content: "\f245";
}

.note-editor .fa.fa-underline:before,
.note-popover .fa.fa-underline:before {
  content: "\f24f";
}

.note-editor .fa.fa-font:before,
.note-popover .fa.fa-font:before {
  content: "\f242";
}

.note-editor .fa.fa-list-ul:before,
.note-popover .fa.fa-list-ul:before {
  content: "\f247";
}

.note-editor .fa.fa-list-ol:before,
.note-popover .fa.fa-list-ol:before {
  content: "\f248";
}

.note-editor .fa.fa-align-left:before,
.note-popover .fa.fa-align-left:before {
  content: "\f23b";
}

.note-editor .fa.fa-align-right:before,
.note-popover .fa.fa-align-right:before {
  content: "\f23c";
}

.note-editor .fa.fa-align-center:before,
.note-popover .fa.fa-align-center:before {
  content: "\f239";
}

.note-editor .fa.fa-align-justify:before,
.note-popover .fa.fa-align-justify:before {
  content: "\f23a";
}

.note-editor .fa.fa-indent:before,
.note-popover .fa.fa-indent:before {
  content: "\f244";
}

.note-editor .fa.fa-outdent:before,
.note-popover .fa.fa-outdent:before {
  content: "\f243";
}

.note-editor .fa.fa-text-height:before,
.note-popover .fa.fa-text-height:before {
  content: "\f246";
}

.note-editor .fa.fa-table:before,
.note-popover .fa.fa-table:before {
  content: "\f320";
}

.note-editor .fa.fa-link:before,
.note-popover .fa.fa-link:before {
  content: "\f18e";
}

.note-editor .fa.fa-picture-o:before,
.note-popover .fa.fa-picture-o:before {
  content: "\f17f";
}

.note-editor .fa.fa-minus:before,
.note-popover .fa.fa-minus:before {
  content: "\f22f";
}

.note-editor .fa.fa-arrows-alt:before,
.note-popover .fa.fa-arrows-alt:before {
  content: "\f16d";
}

.note-editor .fa.fa-code:before,
.note-popover .fa.fa-code:before {
  content: "\f13a";
}

.note-editor .fa.fa-question:before,
.note-popover .fa.fa-question:before {
  content: "\f1f5";
}

.note-editor .fa.fa-eraser:before,
.note-popover .fa.fa-eraser:before {
  content: "\f23f";
}

.note-editor .fa.fa-square:before,
.note-popover .fa.fa-square:before {
  content: "\f279";
}

.note-editor .fa.fa-circle-o:before,
.note-popover .fa.fa-circle-o:before {
  content: "\f26c";
}

.note-editor .fa.fa-times:before,
.note-popover .fa.fa-times:before {
  content: "\f136";
}

.note-editor .note-air-popover .arrow,
.note-popover .note-air-popover .arrow {
  left: 20px;
}

.note-editor {
  overflow: visible;
  border: 1px solid #e4e4e4;
}

.note-editor .note-editable {
  padding: 20px 23px;
}

.bootstrap-datetimepicker-widget {
  padding: 0 !important;
  margin: 0 !important;
  width: auto !important;
}

.bootstrap-datetimepicker-widget:after,
.bootstrap-datetimepicker-widget:before {
  display: none !important;
}

.bootstrap-datetimepicker-widget table td {
  text-shadow: none;
}

.bootstrap-datetimepicker-widget table td span {
  margin: 0;
}

.bootstrap-datetimepicker-widget table td span:hover {
  background: transparent;
}

.bootstrap-datetimepicker-widget .glyphicon {
  font-family: "Material-Design-Iconic-Font";
  font-size: 18px;
}

.bootstrap-datetimepicker-widget .glyphicon-chevron-left:before {
  content: "\f2ff";
}

.bootstrap-datetimepicker-widget .glyphicon-chevron-right:before {
  content: "\f301";
}

.bootstrap-datetimepicker-widget .glyphicon-time:before {
  content: "\f337";
}

.bootstrap-datetimepicker-widget .glyphicon-calendar:before {
  content: "\f32e";
}

.bootstrap-datetimepicker-widget .glyphicon-chevron-up:before {
  content: "\f1e5";
}

.bootstrap-datetimepicker-widget .glyphicon-chevron-down:before {
  content: "\f1e4";
}

.bootstrap-datetimepicker-widget [data-action="togglePicker"] span {
  font-size: 25px;
  color: #ccc;
}

.bootstrap-datetimepicker-widget [data-action="togglePicker"] span:hover {
  color: #333;
}

.bootstrap-datetimepicker-widget a[data-action] {
  color: #009688;
}

.timepicker-picker .btn {
  box-shadow: none !important;
}

.timepicker-picker table tbody tr + tr:not(:last-child) {
  background: #009688;
  color: #fff;
}

.timepicker-picker table tbody tr + tr:not(:last-child) td {
  border-radius: 0;
}

.timepicker-picker .btn,
.timepicker-picker .btn:hover {
  background: #fff;
  color: #333;
}

.datepicker.top {
  -webkit-transform-origin: 0 100% !important;
  -moz-transform-origin: 0 100% !important;
  -ms-transform-origin: 0 100% !important;
  transform-origin: 0 100% !important;
}

.datepicker table thead tr th {
  border-radius: 0;
  color: #fff;
}

.datepicker table thead tr th .glyphicon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  line-height: 29px;
}

.datepicker table thead tr th:hover .glyphicon {
  background: rgba(0, 0, 0, 0.2);
}

.datepicker table thead tr:first-child th {
  background: #009688;
  padding: 20px 0;
}

.datepicker table thead tr:first-child th:hover {
  background: #009688;
}

.datepicker table thead tr:first-child th.picker-switch {
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
}

.datepicker table thead tr:last-child th {
  text-transform: uppercase;
  font-weight: normal;
  font-size: 11px;
}

.datepicker table thead tr:last-child th:first-child {
  padding-left: 20px;
}

.datepicker table thead tr:last-child th:last-child {
  padding-right: 20px;
}

.datepicker table thead tr:last-child:not(:only-child) {
  background: #00877a;
}

.datepicker table tbody tr:last-child td {
  padding-bottom: 25px;
}

.datepicker table tbody tr td:first-child {
  padding-left: 13px;
}

.datepicker table tbody tr td:last-child {
  padding-right: 13px;
}

.datepicker table td.day {
  width: 35px;
  height: 35px;
  line-height: 20px;
  color: #333;
  position: relative;
  padding: 0;
  background: transparent;
}

.datepicker table td.day:hover {
  background: none;
}

.datepicker table td.day:before {
  content: "";
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-bottom: -33px;
  display: inline-block;
  background: transparent;
  position: static;
  text-shadow: none;
}

.datepicker table td.day.old,
.datepicker table td.day.new {
  color: #cdcdcd;
}

.datepicker table td:not(.today):not(.active):hover:before {
  background: #f0f0f0;
}

.datepicker table td.today {
  color: #333;
}

.datepicker table td.today:before {
  background-color: #e2e2e2;
}

.datepicker table td.active {
  color: #fff;
}

.datepicker table td.active:before {
  background-color: #009688;
}

.datepicker-months .month,
.datepicker-years .year,
.timepicker-minutes .minute,
.timepicker-hours .hour {
  border-radius: 50%;
}

.datepicker-months .month:not(.active):hover,
.datepicker-years .year:not(.active):hover,
.timepicker-minutes .minute:not(.active):hover,
.timepicker-hours .hour:not(.active):hover {
  background: #f0f0f0;
}

.datepicker-months .month.active,
.datepicker-years .year.active,
.timepicker-minutes .minute.active,
.timepicker-hours .hour.active {
  background: #009688;
}

.timepicker-minutes .minute,
.timepicker-hours .hour {
  padding: 0;
}

.fileinput {
  position: relative;
  padding-right: 35px;
}

.fileinput .close {
  position: absolute;
  top: 5px;
  font-size: 12px;
  float: none;
  opacity: 1;
  font-weight: 500;
  border: 1px solid #ccc;
  width: 19px;
  text-align: center;
  height: 19px;
  line-height: 15px;
  border-radius: 50%;
  right: 0;
}

.fileinput .close:hover {
  background: #eee;
}

.fileinput .input-group-addon {
  padding: 0 10px;
  vertical-align: middle;
}

.fileinput .fileinput-preview {
  width: 200px;
  height: 150px;
  position: relative;
}

.fileinput .fileinput-preview img {
  display: inline-block;
  vertical-align: middle;
  margin-top: -13px;
}

.fileinput .fileinput-preview:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
}

.lg-outer .lg-thumb-outer {
  background-color: #1d1d1d;
}

.lg-outer .lg-thumb-item {
  border-radius: 50%;
  width: 60px !important;
  display: inline-block;
  height: 60px;
  border: 0;
  float: none;
  margin: 0 5px;
}

.lg-outer .lg-thumb-item:hover {
  box-shadow: 0 0 0px 4px rgba(255, 255, 255, 0.1);
}

.lg-outer .lg-image {
  border-radius: 3px;
}

.lg-outer .lg-toogle-thumb {
  border-radius: 50%;
  color: #333;
  height: 51px;
  width: 51px;
  line-height: 41px;
  background-color: #fff;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 800ms;
  transition-duration: 800ms;
}

.lg-outer .lg-toogle-thumb:hover {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
  color: #000;
}

.lg-outer:not(.lg-thumb-open) .lg-toogle-thumb {
  top: -70px;
}

.lg-outer.lg-thumb-open .lg-toogle-thumb {
  top: -26px;
}

.lg-thumb.group {
  padding: 20px 0;
}

.lg-slide em {
  font-style: normal;
}

.lg-slide em h3 {
  color: #fff;
  margin-bottom: 5px;
}

.lg-slide .video-cont {
  box-shadow:
    0 8px 17px rgba(0, 0, 0, 0.2),
    0 6px 20px rgba(0, 0, 0, 0.19);
}

.lg-outer .lg-item {
  background-image: none !important;
}

.lg-outer .lg-item:before {
  content: "";
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-right-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  -webkit-animation-name: loader;
  animation-name: loader;
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loading > i {
  width: 100%;
  height: 100%;
  display: block;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: loader 1.1s infinite linear;
  animation: loader 1.1s infinite linear;
  border-left-color: #fff;
  border-style: solid;
}

.sweet-alert {
  border-radius: 2px;
  padding: 10px 30px;
}

.sweet-alert h2 {
  font-size: 16px;
  font-weight: 400;
  position: relative;
  z-index: 1;
}

.sweet-alert .lead {
  font-size: 13px;
}

.sweet-alert .btn {
  padding: 6px 12px;
  font-size: 13px;
  margin: 20px 2px 0;
  box-shadow: none !important;
}

.twitter-typeahead {
  width: 100%;
}

.twitter-typeahead .tt-menu {
  min-width: 200px;
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  display: none;
}

.twitter-typeahead .tt-menu.tt-open:not(.tt-empty) {
  display: block;
}

.twitter-typeahead .tt-suggestion {
  padding: 8px 17px;
  color: #333;
  cursor: pointer;
}

.twitter-typeahead .tt-suggestion:hover,
.twitter-typeahead .tt-cursor {
  background-color: rgba(0, 0, 0, 0.075);
}

.twitter-typeahead .tt-hint {
  color: #818181 !important;
}

.mCSB_scrollTools {
  width: 5px;
}

.mCSB_scrollTools .mCSB_dragger_bar {
  border-radius: 0 !important;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCSB_scrollTools.mCSB_scrollTools_vertical {
  margin: 0 !important;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  height: 10px;
}

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background: rgba(0, 0, 0, 0.4);
}

.mCS-minimal-dark.mCSB_scrollTools_onDrag .mCSB_dragger .mCSB_dragger_bar {
  background: rgba(0, 0, 0, 0.5) !important;
}

.mCSB_inside > .mCSB_container {
  margin-right: 0;
}
