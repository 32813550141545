@import url("https://fonts.googleapis.com/css?family=Poppins:400,600,700");
@import url("https://fonts.googleapis.com/css?family=Inconsolata:400,700"); /* vietnamese */
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"); /* numbers */

button {
  border: none !important;
}

html {
  font-size: 1em;
}
body {
  background-color: #f0f0f0 !important;
}

.input-group-text {
  border: none;
}

.grayLayer {
  opacity: 0.1 !important;
}

.fixedWidthFont {
  font-family: "Inconsolata", monospace;
  font-weight: 700;
  font-size: 16px;
}

.cool_scroll {
  -webkit-overflow-scrolling: touch;
}

* {
  padding: 0;
  margin: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input,
input:before,
input:after,
textarea,
textarea:before,
textarea:after {
  -webkit-user-select: auto;
  -khtml-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}

html,
body,
#fullheight {
  min-height: 100% !important;
  height: 100%;
  text-align: left !important;
  overflow-y: hidden;
  word-break: keep-all !important;
}

body > div {
  height: 100%;
  overflow: scroll;
}

#fullheight {
  width: 250px;
  background: blue;
}

html,
body {
  min-height: 100% !important;
  margin: 0;
  padding: 0;
}

#root,
.mobile-application {
  min-height: 100% !important;
  display: flex;
  flex-direction: column;
}

.radio-inline,
.checkbox-inline {
  font-weight: 600 !important;
}

.control-label {
  font-weight: bolder;
}

body,
.popup-bg {
  font-family: "Poppins", sans-serif !important;
  font-size: 15px;
  font-weight: 500;
  color: #22153d !important;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background: #f0f0f0 !important;
}

#size {
  height: 100%;
}

#viewport {
  display: flex;
  flex: 1;
}

.tab {
  height: 65px;
  text-transform: uppercase;
  background: -moz-linear-gradient(
    0% 50% 0deg,
    rgba(233, 233, 233, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(0deg, rgba(233, 233, 233, 1) 0%, rgba(255, 255, 255, 1) 100%);
  background: -webkit-gradient(
    linear,
    0% 50%,
    100% 50%,
    color-stop(0, rgba(233, 233, 233, 1)),
    color-stop(1, rgba(255, 255, 255, 1))
  );
  background: -o-linear-gradient(0deg, rgba(233, 233, 233, 1) 0%, rgba(255, 255, 255, 1) 100%);
  background: -ms-linear-gradient(0deg, rgba(233, 233, 233, 1) 0%, rgba(255, 255, 255, 1) 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#E9E9E9', endColorstr='#FFFFFF' ,GradientType=0)";
  background: linear-gradient(90deg, rgba(233, 233, 233, 1) 0%, rgba(255, 255, 255, 1) 100%);
  opacity: 0.4;
  filter: alpha(opacity=40) progid: DXImageTransform.Microsoft.Alpha(opacity=40) progid:
    DXImageTransform.Microsoft.gradient(
      startColorstr= "#E9E9E9",
      endColorstr= "#FFFFFF",
      GradientType=1
    );
  vertical-align: middle;
}
.tab center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.active-tab {
  background: #22153d !important;
  color: #ffffff;
  opacity: 1;
}

.active-tab:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px;
  width: 0;
  height: 0;
  border-top: solid 10px #22153d;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
}

input[type="text"],
input[type="password"] {
  text-indent: 10px;
  background: #ffffff;
  background: rgba(255, 255, 255, 1);
  border-style: Solid;

  border-width: 2px 2px 2px 2px !important;
  border-radius: 13px;
  background: #ffffff;
  background: rgba(255, 255, 255, 1);
  border-color: #c9c9c9;
  border-color: rgba(201, 201, 201, 1);
  height: 45px;
}

.splitter {
  border-bottom: solid;
  border-bottom-color: #ffffff;
  border-width: 2px;
  font-weight: 600;
  text-indent: 10px;
}

.sub-menu {
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  bottom: 0;
  right: 0;
  width: auto;
  height: 100%;
  padding-top: 5px;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.sub-menu-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.icon-container {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 20;
  height: auto;
  min-height: 425px;
  max-height: 90%;
}

.icon-item {
  margin: 10px 0;
  cursor: pointer;
}

.modal {
  display: block !important;
  z-index: 9999;
  visibility: visible;
}

.popupAbsolute {
  position: absolute;
  z-index: 9999999;
}

.control-label {
  font-size: 12px;
  font-weight: bold;
}

@-webkit-keyframes active-menu {
  from {
    background: #ffffff;
  }
  to {
    background: rgba(83, 202, 223, 1);
  }
}

@keyframes active-menu {
  from {
    background: rgba(255, 255, 255, 1);
  }
  to {
    background: rgba(10, 180, 210, 1);
  }
}

.menu-button,
.menu-button-active {
  background: rgba(255, 255, 255, 1);
  border-radius: 10px / 13px;
  -moz-border-radius: 10px / 13px;
  -webkit-border-radius: 10px / 13px;
  position: relative;
  width: 38px;
  height: 32px;
  line-height: 32px;
  vertical-align: middle;
  display: inline-flex; /* Use inline-flex to keep them inline and use flexbox */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  margin: -5px 40px 10px 0;
  text-align: center; /* Center text horizontally */
}

.menu-button-active {
  background: -moz-linear-gradient(
    50% 100% 90deg,
    rgba(10, 180, 210, 1) 0%,
    rgba(83, 202, 223, 1) 100%
  );
  background: -webkit-linear-gradient(90deg, rgba(10, 180, 210, 1) 0%, rgba(83, 202, 223, 1) 100%);
  background: -webkit-gradient(
    linear,
    50% 100%,
    50% 0%,
    color-stop(0, rgba(10, 180, 210, 1)),
    color-stop(1, rgba(83, 202, 223, 1))
  );
  background: -o-linear-gradient(90deg, rgba(10, 180, 210, 1) 0%, rgba(83, 202, 223, 1) 100%);
  background: -ms-linear-gradient(90deg, rgba(10, 180, 210, 1) 0%, rgba(83, 202, 223, 1) 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#0AB4D2', endColorstr='#53CADF' ,GradientType=0)";
  background: linear-gradient(0deg, rgba(10, 180, 210, 1) 0%, rgba(83, 202, 223, 1) 100%);

  animation-name: active-menu;
  animation-duration: 0.2s;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#53CADF',endColorstr='#0AB4D2' , GradientType=0);
}

.menu-button:last-child,
.menu-button-active:last-child {
  margin-right: 0;
}

.menu-button-badge,
.menu-item-badge {
  display: inline-block;
  background: red;
  color: white;
  border-radius: 10px;
  font-weight: bold;
  width: 12px;
  height: 12px;
  font-size: 11px;
  line-height: 12px;
  vertical-align: middle;
  text-align: center;
  position: absolute;
  right: -5px;
  top: -3px;
}

.menu-item-badge {
  left: 40px;
  top: 5px;
  right: auto;
  text-indent: 0;
}
/*
.menu-button-active .menu-button-badge {
    display:none;
}*/

.message span {
  font-size: 12px;
}
.message p {
  padding: 0;
  margin: 10px 0 5px 0;
}

.progress {
  border-radius: 10px;
  max-height: 4px !important;
}

.progress-bar-success {
  background-color: #32c388 !important;
  border-radius: 10px;
}

#password-checkbox:checked,
#asia-checkbox:checked {
  background-color: green !important;
  border-color: green !important;
}

.body-blue {
  height: 100%;
  background: -moz-linear-gradient(
    50% 128.43% 90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(207, 232, 230, 1) 41.62%,
    rgba(201, 226, 232, 1) 51.61%,
    rgba(182, 210, 236, 1) 65.58%,
    rgba(153, 183, 244, 1) 81.89%,
    rgba(111, 146, 255, 1) 99.84%,
    rgba(111, 146, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(207, 232, 230, 1) 41.62%,
    rgba(201, 226, 232, 1) 51.61%,
    rgba(182, 210, 236, 1) 65.58%,
    rgba(153, 183, 244, 1) 81.89%,
    rgba(111, 146, 255, 1) 99.84%,
    rgba(111, 146, 255, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    50% 128.43%,
    50% 0%,
    color-stop(0, rgba(255, 255, 255, 1)),
    color-stop(0.4162, rgba(207, 232, 230, 1)),
    color-stop(0.5161, rgba(201, 226, 232, 1)),
    color-stop(0.6558, rgba(182, 210, 236, 1)),
    color-stop(0.8189, rgba(153, 183, 244, 1)),
    color-stop(0.9984, rgba(111, 146, 255, 1)),
    color-stop(1, rgba(111, 146, 255, 1))
  );
  background: -o-linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(207, 232, 230, 1) 41.62%,
    rgba(201, 226, 232, 1) 51.61%,
    rgba(182, 210, 236, 1) 65.58%,
    rgba(153, 183, 244, 1) 81.89%,
    rgba(111, 146, 255, 1) 99.84%,
    rgba(111, 146, 255, 1) 100%
  );
  background: -ms-linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(207, 232, 230, 1) 41.62%,
    rgba(201, 226, 232, 1) 51.61%,
    rgba(182, 210, 236, 1) 65.58%,
    rgba(153, 183, 244, 1) 81.89%,
    rgba(111, 146, 255, 1) 99.84%,
    rgba(111, 146, 255, 1) 100%
  );
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF', endColorstr='#6F92FF' ,GradientType=0)";
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(207, 232, 230, 1) 41.62%,
    rgba(201, 226, 232, 1) 51.61%,
    rgba(182, 210, 236, 1) 65.58%,
    rgba(153, 183, 244, 1) 81.89%,
    rgba(111, 146, 255, 1) 99.84%,
    rgba(111, 146, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#6F92FF',endColorstr='#FFFFFF' , GradientType=0);
}

.language-button {
  width: 100%;
  font-weight: 700;
}

.standard-button {
  padding: 10px;
  border-width: 0;
  background: -moz-linear-gradient(
    50% 100% 90deg,
    rgba(233, 233, 233, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  ) !important;
  background: -webkit-linear-gradient(
    90deg,
    rgba(233, 233, 233, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  ) !important;
  background: -webkit-gradient(
    linear,
    50% 100%,
    50% 0%,
    color-stop(0, rgba(233, 233, 233, 1)),
    color-stop(1, rgba(255, 255, 255, 1))
  ) !important;
  background: -o-linear-gradient(
    90deg,
    rgba(233, 233, 233, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  ) !important;
  background: -ms-linear-gradient(
    90deg,
    rgba(233, 233, 233, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  ) !important;
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#E9E9E9', endColorstr='#FFFFFF' ,GradientType=0)" !important;
  background: linear-gradient(
    0deg,
    rgba(233, 233, 233, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  ) !important;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF',endColorstr='#E9E9E9' , GradientType=0) !important;
  color: #22153d !important;
}

.active.standard-button {
  background: #ffffff !important;
}

.btn-info {
  font-size: 12px;
  font-weight: 600;
}

.btn-success {
  font-size: 14px;
  font-weight: 300;
  background: -moz-linear-gradient(
    50% 100% 90deg,
    rgba(50, 195, 136, 1) 0%,
    rgba(111, 213, 172, 1) 100%
  );
  background: -webkit-linear-gradient(90deg, rgba(50, 195, 136, 1) 0%, rgba(111, 213, 172, 1) 100%);
  background: -webkit-gradient(
    linear,
    50% 100%,
    50% 0%,
    color-stop(0, rgba(50, 195, 136, 1)),
    color-stop(1, rgba(111, 213, 172, 1))
  );
  background: -o-linear-gradient(90deg, rgba(50, 195, 136, 1) 0%, rgba(111, 213, 172, 1) 100%);
  background: -ms-linear-gradient(90deg, rgba(50, 195, 136, 1) 0%, rgba(111, 213, 172, 1) 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#32C388', endColorstr='#6FD5AC' ,GradientType=0)";
  background: linear-gradient(0deg, rgba(50, 195, 136, 1) 0%, rgba(111, 213, 172, 1) 100%);
  border-radius: 14px;
  -moz-border-radius: 14px;
  -webkit-border-radius: 14px;
  filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=0, OffY=3, Color='#000000') progid:DXImageTransform.Microsoft.gradient(startColorstr='#6FD5AC',endColorstr='#32C388' , GradientType=0);
  color: #ffffff;
  box-shadow: none !important;
}

.btn-danger {
  border-radius: 14px;
  -moz-border-radius: 14px;
  -webkit-border-radius: 14px;
}

.btn-default {
  background: -moz-linear-gradient(
    50% 100% 90deg,
    rgba(10, 180, 210, 1) 0%,
    rgba(59, 195, 219, 1) 100%
  );
  background: -webkit-linear-gradient(90deg, rgba(10, 180, 210, 1) 0%, rgba(59, 195, 219, 1) 100%);
  background: -webkit-gradient(
    linear,
    50% 100%,
    50% 0%,
    color-stop(0, rgba(10, 180, 210, 1)),
    color-stop(1, rgba(59, 195, 219, 1))
  );
  background: -o-linear-gradient(90deg, rgba(10, 180, 210, 1) 0%, rgba(59, 195, 219, 1) 100%);
  background: -ms-linear-gradient(90deg, rgba(10, 180, 210, 1) 0%, rgba(59, 195, 219, 1) 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#0AB4D2', endColorstr='#3BC3DB' ,GradientType=0)";
  background: linear-gradient(0deg, rgba(10, 180, 210, 1) 0%, rgba(59, 195, 219, 1) 100%);
  border-radius: 14px;
  -moz-border-radius: 14px;
  -webkit-border-radius: 14px;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3BC3DB',endColorstr='#0AB4D2' , GradientType=0);
  color: #ffffff !important;
  font-weight: 600;
  box-shadow: none !important;
}

.btn-default:active {
  background: -moz-linear-gradient(
    50% 100% 90deg,
    rgba(10, 180, 210, 1) 0%,
    rgba(59, 195, 219, 1) 100%
  );
  background: -webkit-linear-gradient(90deg, rgba(10, 180, 210, 1) 0%, rgba(59, 195, 219, 1) 100%);
  background: -webkit-gradient(
    linear,
    50% 100%,
    50% 0%,
    color-stop(0, rgba(10, 180, 210, 1)),
    color-stop(1, rgba(59, 195, 219, 1))
  );
  background: -o-linear-gradient(90deg, rgba(10, 180, 210, 1) 0%, rgba(59, 195, 219, 1) 100%);
  background: -ms-linear-gradient(90deg, rgba(10, 180, 210, 1) 0%, rgba(59, 195, 219, 1) 100%);
  filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#0AB4D2', endColorstr='#3BC3DB' ,GradientType=0)";
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#0AB4D2', endColorstr='#3BC3DB' ,GradientType=0)";
  background: linear-gradient(0deg, rgba(10, 180, 210, 1) 0%, rgba(59, 195, 219, 1) 100%);
}

.standard-button:active {
  background: #ffffff;
  background: rgba(255, 255, 255, 1);
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 3px;
  left: -20px;
  height: 15px;
  width: 15px;
  background: #cccccc;
  border-radius: 5px;
}

.p-10 .checkmark {
  left: auto;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.left-enter {
  transform: translate(100%);
}
.left-enter.left-enter-active {
  transform: translate(0%);
  transition: transform 200ms ease-in-out;
}
.left-exit {
  transform: translate(0%);
}
.left-exit.left-exit-active {
  transform: translate(-100%);
  transition: transform 200ms ease-in-out;
}

.right-enter {
  transform: translate(-100%);
}
.right-enter.right-enter-active {
  transform: translate(0%);
  transition: transform 200ms ease-in-out;
}
.right-exit {
  transform: translate(0%);
}
.right-exit.right-exit-active {
  transform: translate(100%);
  transition: transform 200ms ease-in-out;
}

.achievement-button {
  width: 100px;
  height: 100px;
  margin: 5px;
  display: inline-block;
  background: -moz-linear-gradient(
    50% 100% 90deg,
    rgba(233, 233, 233, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(233, 233, 233, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    50% 100%,
    50% 0%,
    color-stop(0, rgba(233, 233, 233, 1)),
    color-stop(1, rgba(255, 255, 255, 1))
  );
  background: -o-linear-gradient(90deg, rgba(233, 233, 233, 1) 0%, rgba(255, 255, 255, 1) 100%);
  background: -ms-linear-gradient(90deg, rgba(233, 233, 233, 1) 0%, rgba(255, 255, 255, 1) 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#E9E9E9', endColorstr='#FFFFFF' ,GradientType=0)";
  background: linear-gradient(0deg, rgba(233, 233, 233, 1) 0%, rgba(255, 255, 255, 1) 100%);
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=0, OffY=3, Color='#000000') progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF',endColorstr='#E9E9E9' , GradientType=0);
}

.achievement-missing {
  width: 100px;
  height: 100px;
  opacity: 0.4;
  margin: 5px;
  display: inline-block;
  background: -moz-linear-gradient(
    50% 100% 90deg,
    rgba(233, 233, 233, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(233, 233, 233, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    50% 100%,
    50% 0%,
    color-stop(0, rgba(233, 233, 233, 1)),
    color-stop(1, rgba(255, 255, 255, 1))
  );
  background: -o-linear-gradient(90deg, rgba(233, 233, 233, 1) 0%, rgba(255, 255, 255, 1) 100%);
  background: -ms-linear-gradient(90deg, rgba(233, 233, 233, 1) 0%, rgba(255, 255, 255, 1) 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#E9E9E9', endColorstr='#FFFFFF' ,GradientType=0)";
  background: linear-gradient(0deg, rgba(233, 233, 233, 1) 0%, rgba(255, 255, 255, 1) 100%);
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=0, OffY=3, Color='#000000') progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF',endColorstr='#E9E9E9' , GradientType=0);
}

.module {
  background: #ffffff;
  background: rgba(255, 255, 255, 1);
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}

.icon {
  border: solid;
  border-width: 2px;
  border-color: #cccccc;
  width: 24px;
  height: 24px;
}

.circleBase {
  border-radius: 50%;
}

.achievement-overlay {
  background: #282828;
  opacity: 0.8;
  height: 100%;
}

.comment-btn {
  color: #ffffff;
  padding: 10px;

  text-transform: uppercase;
  background: -moz-linear-gradient(
    50% 100% 90deg,
    rgba(34, 21, 61, 1) 0%,
    rgba(78, 68, 100, 1) 100%
  );
  background: -webkit-linear-gradient(90deg, rgba(34, 21, 61, 1) 0%, rgba(78, 68, 100, 1) 100%);
  background: -webkit-gradient(
    linear,
    50% 100%,
    50% 0%,
    color-stop(0, rgba(34, 21, 61, 1)),
    color-stop(1, rgba(78, 68, 100, 1))
  );
  background: -o-linear-gradient(90deg, rgba(34, 21, 61, 1) 0%, rgba(78, 68, 100, 1) 100%);
  background: -ms-linear-gradient(90deg, rgba(34, 21, 61, 1) 0%, rgba(78, 68, 100, 1) 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#22153D', endColorstr='#4E4464' ,GradientType=0)";
  background: linear-gradient(0deg, rgba(34, 21, 61, 1) 0%, rgba(78, 68, 100, 1) 100%);
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=0, OffY=3, Color='#000000') progid:DXImageTransform.Microsoft.gradient(startColorstr='#4E4464',endColorstr='#22153D' , GradientType=0);
}

.tag {
  padding-left: 10px;
  padding-right: 10px;
  background: #d45047;
  background: rgba(212, 80, 71, 1);
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=0, OffY=3, Color='#000000');
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 1000ms ease-in;
}

.matching-pairs {
  background: #ffffff;
  background: rgba(255, 255, 255, 1);
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  margin: 20px;
  -webkit-box-shadow: 0 5px 24px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 5px 24px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 0 5px 24px -4px rgba(0, 0, 0, 0.75);
}

.selected-drop {
  background: #0ab4d2;
  background: rgba(10, 180, 210, 1);
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  width: 90%;
  height: 40px;
  line-height: 40px;
  color: #ffffff;
  font-size: 12px;
}

.selected-drop.success {
  background: #32c388;
}

.selected-drop.wrong {
  background: #d83c38;
}

.not-selected-drop {
  background: #c9c9c9;
  background: rgba(201, 201, 201, 1);
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  width: 90%;
  height: 40px;
  line-height: 40px;
  font-size: 30px;
}

.not-selected-drop-two {
  background: #ffffff;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 30px;
  border: solid;
  border-width: 2px;
  border-color: #cccccc;
}

.close-selector {
  position: relative;
  float: right;
  right: 10%;
  top: 8px;
  color: #ffffff;
  text-transform: lowercase;
}

.long-text {
  padding: 20px;
  padding-bottom: 50px;
  background: #f0f0f0;
  -webkit-box-shadow: 0 5px 15px -2px rgba(90, 90, 90, 0.75);
  -moz-box-shadow: 0 5px 15px -2px rgba(90, 90, 90, 0.75);
  box-shadow: 0 5px 15px -2px rgba(90, 90, 90, 0.75);
  font-weight: 500;
  font-size: 14px;
}

.btn-primary {
  padding: 15px;
  font-weight: 600;
  background: -moz-linear-gradient(
    50% 100% 90deg,
    rgba(10, 180, 210, 1) 0%,
    rgba(59, 195, 219, 1) 100%
  );
  background: -webkit-linear-gradient(90deg, rgba(10, 180, 210, 1) 0%, rgba(59, 195, 219, 1) 100%);
  background: -webkit-gradient(
    linear,
    50% 100%,
    50% 0%,
    color-stop(0, rgba(10, 180, 210, 1)),
    color-stop(1, rgba(59, 195, 219, 1))
  );
  background: -o-linear-gradient(90deg, rgba(10, 180, 210, 1) 0%, rgba(59, 195, 219, 1) 100%);
  background: -ms-linear-gradient(90deg, rgba(10, 180, 210, 1) 0%, rgba(59, 195, 219, 1) 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#0AB4D2', endColorstr='#3BC3DB' ,GradientType=0)";
  background: linear-gradient(0deg, rgba(10, 180, 210, 1) 0%, rgba(59, 195, 219, 1) 100%);
  border-radius: 14px;
  -moz-border-radius: 14px;
  -webkit-border-radius: 14px;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3BC3DB',endColorstr='#0AB4D2' , GradientType=0);
}

.ln-block {
  margin: 20px;
}

.question {
  position: relative;
  background: #22153d;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  color: #ffffff;
  padding: 10px;
  padding-top: 0;
  padding-bottom: 1px;
}

.question:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-top-color: #22153d;
  border-bottom: 10px;
  border-right: 0;
  margin-left: 30%;
  margin-bottom: -20px;
}

.question-badge {
  position: relative;
  top: -20px;
  background: #0ab4d2;
  border-radius: 50%;
  border: solid;
  border-width: 4px;
  border-color: #ffffff;
  -webkit-box-shadow: 0 5px 15px -2px rgba(90, 90, 90, 0.75);
  -moz-box-shadow: 0 5px 15px -2px rgba(90, 90, 90, 0.75);
  box-shadow: 0 5px 15px -2px rgba(90, 90, 90, 0.75);
}

.question-text {
  position: relative;
  top: -10px;
}

.header {
  background: #f0f0f0 !important;
  background: rgba(240, 240, 240, 1);
  box-shadow: 0 10px 50px rgba(40, 40, 40, 0.2);
}

.audio-player {
  background: #ffffff !important;
  background: rgba(240, 240, 240, 1);
  box-shadow: 0 10px 10px rgba(40, 40, 40, 0.2);
  padding: 15px 0 15px 20px;
}

.WSWA {
  background: #0ab4d2 !important;
  border: none !important;
}

.WSWC {
  background: #ffb86b !important;
  border: none !important;
}

.WSWB {
  background: #f26173 !important;
  border: none !important;
}

.grey-media-button {
  background: -moz-linear-gradient(
    50% 100% 90deg,
    rgba(201, 201, 201, 1) 0%,
    rgba(240, 240, 240, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(201, 201, 201, 1) 0%,
    rgba(240, 240, 240, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    50% 100%,
    50% 0%,
    color-stop(0, rgba(201, 201, 201, 1)),
    color-stop(1, rgba(240, 240, 240, 1))
  );
  background: -o-linear-gradient(90deg, rgba(201, 201, 201, 1) 0%, rgba(240, 240, 240, 1) 100%);
  background: -ms-linear-gradient(90deg, rgba(201, 201, 201, 1) 0%, rgba(240, 240, 240, 1) 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#C9C9C9', endColorstr='#F0F0F0' ,GradientType=0)";
  background: linear-gradient(0deg, rgba(201, 201, 201, 1) 0%, rgba(240, 240, 240, 1) 100%);
  border-radius: 50%;
  height: 35px;
  width: 35px;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F0F0F0',endColorstr='#C9C9C9' , GradientType=0);
}

.play-button {
  background: -moz-linear-gradient(
    50% 100% 90deg,
    rgba(50, 195, 136, 1) 0%,
    rgba(111, 213, 172, 1) 100%
  );
  background: -webkit-linear-gradient(90deg, rgba(50, 195, 136, 1) 0%, rgba(111, 213, 172, 1) 100%);
  background: -webkit-gradient(
    linear,
    50% 100%,
    50% 0%,
    color-stop(0, rgba(50, 195, 136, 1)),
    color-stop(1, rgba(111, 213, 172, 1))
  );
  background: -o-linear-gradient(90deg, rgba(50, 195, 136, 1) 0%, rgba(111, 213, 172, 1) 100%);
  background: -ms-linear-gradient(90deg, rgba(50, 195, 136, 1) 0%, rgba(111, 213, 172, 1) 100%);
  filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#32C388', endColorstr='#6FD5AC' ,GradientType=0)";
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#32C388', endColorstr='#6FD5AC' ,GradientType=0)";
  background: linear-gradient(0deg, rgba(50, 195, 136, 1) 0%, rgba(111, 213, 172, 1) 100%);
  border-radius: 50%;
  height: 50px;
  width: 50px;
}

.pause-button {
  background: -moz-linear-gradient(
    50% 100% 90deg,
    rgba(10, 180, 210, 1) 0%,
    rgba(59, 195, 219, 1) 100%
  );
  background: -webkit-linear-gradient(90deg, rgba(10, 180, 210, 1) 0%, rgba(59, 195, 219, 1) 100%);
  background: -webkit-gradient(
    linear,
    50% 100%,
    50% 0%,
    color-stop(0, rgba(10, 180, 210, 1)),
    color-stop(1, rgba(59, 195, 219, 1))
  );
  background: -o-linear-gradient(90deg, rgba(10, 180, 210, 1) 0%, rgba(59, 195, 219, 1) 100%);
  background: -ms-linear-gradient(90deg, rgba(10, 180, 210, 1) 0%, rgba(59, 195, 219, 1) 100%);
  filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#0AB4D2', endColorstr='#3BC3DB' ,GradientType=0)";
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#0AB4D2', endColorstr='#3BC3DB' ,GradientType=0)";
  background: linear-gradient(0deg, rgba(10, 180, 210, 1) 0%, rgba(59, 195, 219, 1) 100%);
  border-radius: 50%;
  height: 50px;
  width: 50px;
}
.circle {
  border-radius: 50%;
  box-shadow: 0 20px 20px rgba(40, 40, 40, 0.2);
}

.circle-inner {
  border-radius: 50%;
  background-color: #ffffff;
  position: absolute;
  width: 10%;
  left: 4%;
  z-index: -6;
  box-shadow: 0 20px 20px rgba(40, 40, 40, 0.2);
}

.audio-tracker {
  margin-top: 20px;
  height: 8px !important;
  max-height: 8px !important;
}

.progress-bar {
  background-color: #22153d;
}

.multichoice-button {
  background-color: #22153d;
  color: #ffffff;
  width: 90%;
  text-align: center;
  vertical-align: middle;
  margin: auto;
  border-radius: 25px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;

  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.speaking-text {
  background: #22153d;
  background: rgba(34, 21, 61, 1);
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  color: #ffffff;
  width: 95%;
  vertical-align: middle;
  margin: auto;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  text-indent: 10px;
  margin: 10px auto 10px auto;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  font-size: 12px;
}

.speaking-text2 {
  vertical-align: middle;
  margin: auto;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin: 15px auto 15px auto;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.exam-text {
  background: #f0f0f0; /* Old browsers */
  background: -moz-linear-gradient(top, #d0d0d0 0%, #f0f0f0 15%, #f0f0f0 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #d0d0d0 0%,
    #f0f0f0 15%,
    #f0f0f0 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #d0d0d0 0%,
    #f0f0f0 15%,
    #f0f0f0 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#f0f0f0',GradientType=0 );
  overflow-y: scroll;
  overflow-x: visible;
  padding: 5px 10px 5px 10px;
  white-space: pre-line;
}

.time-text {
  line-height: 20px;
}

.jigsaw-background {
  background: #ffffff;
  background: rgba(255, 255, 255, 1);
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  margin: 20px;
  margin-top: 5px;
  box-shadow: 0 3px 3px rgba(40, 40, 40, 0.25);
  padding: 10px;
}

.jigsaw-inner {
  background: #f0f0f0;
  background: rgba(240, 240, 240, 1);
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  -webkit-box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.2);
  padding: 5px 15px 5px 5px;
  margin-bottom: 10px;
  height: 113px;
  word-break: break-all;
}

.jigsaw-inner > div {
  position: relative;
  width: 110%;
}

.jigsaw1,
.jigsaw2,
.jigsaw3 {
  width: 29%;
  max-width: 29%;
  height: 6em;
  margin: 5px;

  position: relative;
  border-radius: 15px;
  cursor: pointer;
  background: #22153d;
  background: rgba(34, 21, 61, 1);
  float: left;
}

.jigsaw1,
.jigsaw1 span {
  left: -8px;
  background: #22153d;
  background: rgba(34, 21, 61, 1);
}

.jigsaw2,
.jigsaw2 span {
  background: #22153d;
  background: rgba(34, 21, 61, 1);
}

.jigsaw3,
.jigsaw3 span {
  left: -16px;
  background: #22153d;
  background: rgba(34, 21, 61, 1);
}

.text {
  display: block;
  color: #fff;
  font-size: 0.8em;
  text-align: center;
  position: relative;
  background: transparent !important;
  width: 100%;
  padding: 20px;
}

.jigsaw3 .t,
.jigsaw3 .t {
  width: 2em;
  height: 2em;
  position: absolute;
  top: -1.5em;
  left: 2em;
  -webkit-border-radius: 4em;
  -moz-border-radius: 4em;
  border-radius: 4em;
}
.jigsaw1 .rr {
  width: 2em;
  height: 2em;
  top: 2em;
  right: -1em;
  -webkit-border-radius: 4em;
  -moz-border-radius: 4em;
  border-radius: 4em;
  background: #f0f0f0;
  background: rgba(240, 240, 240, 1);
  right: -0.9em;
  left: unset;
  position: absolute;
}

.jigsaw1 .t,
.jigsaw2 .t {
  width: 2em;
  height: 2em;
  position: absolute;
  top: -1.5em;
  left: 2em;
  -webkit-border-radius: 4em;
  -moz-border-radius: 4em;
  border-radius: 4em;
}

.jigsaw1 .r,
.jigsaw2 .r {
  width: 2em;
  height: 2em;
  position: absolute;
  top: 2em;
  right: -1em;
  -webkit-border-radius: 4em;
  -moz-border-radius: 4em;
  border-radius: 4em;
  background: #f0f0f0;
  background: rgba(240, 240, 240, 1);
}

.jigsaw1 .r {
  position: absolute;
  background: #22153d;
  background: rgba(34, 21, 61, 1);
  right: 200px;
}

.jigsaw1 .b,
.jigsaw2 .b {
  width: 1.8em;
  height: 1.8em;
  position: absolute;
  top: 4.5em;
  left: 2.2em;
  -webkit-border-radius: 4em;
  -moz-border-radius: 4em;
  border-radius: 4em;
  background-color: #fff;
}

.jigsaw1 .l,
.jigsaw2 .l {
  width: 1.7em;
  height: 1.7em;
  position: absolute;
  top: 2.15em;
  left: -1em;
  -webkit-border-radius: 4em;
  -moz-border-radius: 4em;
  border-radius: 4em;
}

.jigsaw3 .b,
.jigsaw3 .b {
  width: 1.8em;
  height: 1.8em;
  position: absolute;
  top: 4.5em;
  left: 2.2em;
  -webkit-border-radius: 4em;
  -moz-border-radius: 4em;
  border-radius: 4em;
  background-color: #fff;
}

.jigsaw3 .l,
.jigsaw3 .l {
  width: 1.7em;
  height: 1.7em;
  position: absolute;
  top: 2.15em;
  left: -1em;
  -webkit-border-radius: 4em;
  -moz-border-radius: 4em;
  border-radius: 4em;
}

.jigsaw3 .r {
  position: absolute;
  background: #22153d;
  background: rgba(34, 21, 61, 1);
  right: 1em;
  height: 1.75em;
  top: 2.1em;
  border-radius: 3em;
}

.jigsaw3 .text {
  left: 10px;
}

.backgroundLightGrey,
.backgroundLightGrey .r {
  background: transparent !important;
}

.backgroundLightGrey .text {
  color: #282828 !important;
}

.vertical-align-arrow {
  display: inline-block;
  position: absolute;
  top: 50%;
  margin-top: -15px;
}

.arrow-left {
  left: 5%;
}

.arrow-right {
  right: 5%;
}

.popup {
  position: relative;
  left: 10%;
  top: 15%;

  min-width: 80%;
  width: 80%;
  min-height: 70%;
  max-height: 70%;
  background: -moz-linear-gradient(
    50% 100% 90deg,
    rgba(233, 233, 233, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(233, 233, 233, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    50% 100%,
    50% 0%,
    color-stop(0, rgba(233, 233, 233, 1)),
    color-stop(1, rgba(255, 255, 255, 1))
  );
  background: -o-linear-gradient(90deg, rgba(233, 233, 233, 1) 0%, rgba(255, 255, 255, 1) 100%);
  background: -ms-linear-gradient(90deg, rgba(233, 233, 233, 1) 0%, rgba(255, 255, 255, 1) 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#E9E9E9', endColorstr='#FFFFFF' ,GradientType=0)";
  background: linear-gradient(0deg, rgba(233, 233, 233, 1) 0%, rgba(255, 255, 255, 1) 100%);
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  word-break: keep-all;
  overflow-y: scroll;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF',endColorstr='#E9E9E9' , GradientType=0);
}

.who_said_what {
  display: inline-block;
  border-style: Solid;
  border-color: #f0f0f0;
  border-width: 3px;
  border-radius: 50%;
  width: 74px;
  height: 74px;
  box-shadow: 0 4px 3px rgba(0, 0, 0, 0.3);
  position: relative;
}

.who_said_what.active {
  border-color: #35c287;
}

.who_said_what_top {
  background: #0ab4d2;
}

.who_said_what_left {
  background: #ffb86b;
}

.who_said_what_right {
  background: #f26173;
}

.long-question {
  background: #ffffff;
  padding: 9px;
  margin: 20px;
  border-radius: 100px;
  text-align: left;
}

.circle-no-shadow {
  padding: 10px;
  border-radius: 50%;
}

.long-question-speaking {
  left: 100%;
  position: relative;
  top: -105px;
  margin-left: -130px;
}

.missing-word {
  border-style: Solid;
  border-color: #c9c9c9;
  border-color: rgba(201, 201, 201, 1);
  border-width: 2px;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  padding: 4px;
  margin: 2px;
  min-width: 50px;
  display: inline-block;
}

.found-word {
  padding: 5px;
  margin: 2px;
  display: inline-block;
  color: #ffffff;
  background: #0ab4d2;
  background: rgba(10, 180, 210, 1);
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}

@-webkit-keyframes fadeTo {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.8;
  }
}

@keyframes fadeTo {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.8;
  }
}

.fadeTo {
  -webkit-animation-name: fadeTo;
  animation-name: fadeTo;
}

.circle_button {
  border-radius: 50%;
  background: #ffffff;
  width: 20px;
  height: 20px;
  float: right;
  box-shadow: 0 4px 3px rgba(0, 0, 0, 0.3);
}
.buttonBackgroundRed {
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(top, #e45144 0%, #d83c38 50%, #d83c38 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #e45144 0%,
    #d83c38 50%,
    #d83c38 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #e45144 0%,
    #d83c38 50%,
    #d83c38 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e45144', endColorstr='#d83c38',GradientType=0 );
  width: 90%;
  border-radius: 15px;
  margin: 10px auto 10px auto;
  color: #ffffff;
}

.buttonBackgroundGreen {
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(top, #5ace9d 0%, #35c287 50%, #35c287 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #5ace9d 0%,
    #35c287 50%,
    #35c287 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #5ace9d 0%,
    #35c287 50%,
    #35c287 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5ace9d', endColorstr='#35c287',GradientType=0 );
  width: 90%;
  border-radius: 15px;
  margin: 10px auto 10px auto;
  color: #ffffff;
}

.picker-active {
  color: #0074d9;
}

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #22153d;
}

.tooltip-inner {
  background-color: #22153d;
  color: #ffffff;
  font-family: "Poppins", sans-serif !important;
  font-size: 15px;
  font-weight: 500;
  border-radius: 5px;
  box-shadow: 0 4px 3px rgba(0, 0, 0, 0.3);
}

.box-over {
  position: absolute;
  top: 0;
}

.input-background {
  margin: 25px 0 0 0 !important;
  border-radius: 15px !important;
  background: #ffffff;
  padding: 10px;
}

.speaking-background {
  border-radius: 15px !important;
  background: #ffffff;
  padding: 10px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
}

.textBox-examwriting {
  padding: 10px !important;
  border-radius: 10px !important;
  background: #efeded;
  -moz-box-shadow: inset 0 0 50px #bbbbbb !important;
  -webkit-box-shadow: inset 0 0 50px #bbbbbb !important;
  box-shadow: inset 0 0 50px #bbbbbb !important;
}

.textBox-examwriting:focus {
  padding: 10px !important;
  border-radius: 10px !important;
  background: #ffffff;
  -moz-box-shadow: inset 0 0 50px #e5e5e5 !important;
  -webkit-box-shadow: inset 0 0 50px #e5e5e5 !important;
  box-shadow: inset 0 0 50px #e5e5e5 !important;
}

.textBox-speaking {
  margin: 15px auto 15px auto !important;
  width: 95% !important;
  -moz-box-shadow: inset 0 0 20px #bbbbbb !important;
  -webkit-box-shadow: inset 0 0 20px #bbbbbb !important;
  box-shadow: inset 0 0 20px #bbbbbb !important;

  height: 70px;

  display: flex;
  padding-left: 5px;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;

  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  background: #ffffff;
  background: rgba(240, 240, 240, 1);
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}

.whosaidwhat_text {
  overflow: hidden;
  border-radius: 50%;
  border-color: #f0f0f0;
  border-width: 3px;
}

#who_said_what_letter {
  position: absolute;
  z-index: 10;
  background: #f0f0f0;
  border-radius: 50% !important;
  width: 20px;
  height: 20px;
  top: -10px;
  left: 50%;
  margin-left: -10px;
}

.active > center > #who_said_what_letter {
  background: #35c287;
  color: #f0f0f0;
}

.resultsBackground {
  position: absolute;
  z-index: -1;
  width: 100%;
}

.capitalize {
  text-transform: uppercase !important;
  color: #ffffff;
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
  overflow: hidden;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.switch-wrapper {
  position: relative;
  width: 100%;
}

.switch-wrapper > div {
  position: absolute;
  width: 100%;
}

.text-button-assignment {
  word-break: break-word;
  white-space: normal;
  line-height: 20px;
  display: inline-block;
  margin-left: 10px;
  overflow: hidden;
}

.no-shadow {
  box-shadow: none !important;
}

.language-selection {
  background: rgba(240, 240, 240, 1);
}

.correct-badge {
  border-radius: 50%;
  background: #35c287;
  width: 20px;
  height: 20px;
}

.spinner {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner img {
  width: 100px;
  height: 100px;
  position: relative;
  animation: logoDance ease-in-out 3s infinite alternate;
  filter: brightness(1) saturate(1);
  transform-origin: center;
}

@keyframes logoDance {
  0% {
    left: -200%;
    top: -200%;
    transform: rotate3d(1, 1, 0, 0deg) scale(1);
    filter: brightness(1) saturate(1);
  }
  20% {
    left: 200%;
    top: -200%;
    transform: rotate3d(1, 1, 1, 180deg) scale(1.2);
    filter: brightness(1.05) saturate(1.01);
  }
  40% {
    left: 200%;
    top: 200%;
    transform: rotate3d(0, 1, 0, 360deg) scale(0.8);
    filter: brightness(0.95) saturate(1.05);
  }
  60% {
    left: -200%;
    top: 200%;
    transform: rotate3d(1, 0, 1, 540deg) scale(1.3);
    filter: brightness(1.1) saturate(1.1);
  }
  80% {
    left: 0;
    top: 0;
    transform: rotate3d(1, 1, 1, 720deg) scale(1);
    filter: brightness(1) saturate(1);
  }
  100% {
    left: -200%;
    top: -200%;
    transform: rotate3d(0, 0, 0, 0deg) scale(1);
    filter: brightness(1) saturate(1);
  }
}

.AudioRecorder {
  display: none;
}

#parent,
.parent {
  display: table;
}

#child,
.child {
  display: table-cell;
  vertical-align: middle;
}

.sentence-background {
  background: #ffffff;
  background: rgba(255, 255, 255, 1);
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  box-shadow: 0 4px 3px rgba(0, 0, 0, 0.3);
}

.text-box-shadow {
  box-shadow: 0 4px 3px rgba(0, 0, 0, 0.3);
}

.wordpop-question {
  position: absolute;

  top: 12px;
  left: 3%;
  z-index: 9999;
  background: #ffffff;

  height: 60px;
  padding: 5px;
  width: 94%;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2);
}

.no-radius {
  border-radius: 0;
}

.no-radius > .progress-bar {
  border-radius: 0;
}

.question_toolbar {
  background: #22153d;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  color: #ffffff;
  padding: 0 10px 1px;
  border-radius: 0;
  position: fixed;
  top: 75px;
  left: 0;
  width: 100%;
  text-align: center;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}

.resultsTableBackground {
  background: #ffffff;
  overflow: scroll;
}

.resultsTableBackground > div {
  font-weight: bold;
}

.resultsTableBackground > div > center {
  padding-top: 20px;
}

.resultsTableBackground > div > div {
  padding-bottom: 15px;
}

.achievement-note-container {
  min-width: 50px;
  max-width: 120px;
  position: absolute;
  bottom: 62px;
  z-index: 5000;
  margin: auto;
  padding-bottom: 6px;
  /* left: 279.5px; */
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-left: 42px;
  background: #22153d;
  border-radius: 11px;
  -moz-border-radius: 11px;
  -webkit-border-radius: 11px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}

.achievement-note-background {
  margin-top: 6px;
  margin-left: 6px;
  float: left;
  width: 50px;
  height: 50px;
  background: -moz-linear-gradient(
    50% 100% 90deg,
    rgba(233, 233, 233, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(233, 233, 233, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    50% 100%,
    50% 0%,
    color-stop(0, rgba(233, 233, 233, 1)),
    color-stop(1, rgba(255, 255, 255, 1))
  );
  background: -o-linear-gradient(90deg, rgba(233, 233, 233, 1) 0%, rgba(255, 255, 255, 1) 100%);
  background: -ms-linear-gradient(90deg, rgba(233, 233, 233, 1) 0%, rgba(255, 255, 255, 1) 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#E9E9E9', endColorstr='#FFFFFF' ,GradientType=0)";
  background: linear-gradient(0deg, rgba(233, 233, 233, 1) 0%, rgba(255, 255, 255, 1) 100%);
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF',endColorstr='#E9E9E9' , GradientType=0);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;

  border-top: 15px solid #e0e0e0;
  position: absolute;
  bottom: 47px;
  z-index: 5000;
  margin: auto;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-left: 42px;
}

.achievement-note {
  max-width: 40px;
  max-height: 40px;
  padding: 5px;
  margin-top: 5px;
}

.vertical-align {
  display: flex;
  align-items: center;
}

.rc-slider {
  position: relative;
  height: 14px;
  padding: 5px 0;
  width: 100%;
  border-radius: 20px;
  -ms-touch-action: none;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: #e9e9e9;
  height: 8px;
  border-radius: 6px;
}
.rc-slider-track {
  position: absolute;
  left: 0;
  height: 8px;
  border-radius: 20px;
  background-color: #22153d;
}
.rc-slider-handle {
  position: absolute;
  margin-left: -7px;
  margin-top: -9px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  border-radius: 50%;
  border: solid 4px #0ab4d2;
  background: #ffffff;
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}
.rc-slider-handle:focus {
  outline: none;
}
.rc-slider-handle-click-focused:focus {
  box-shadow: unset;
}

.rc-slider-handle:active {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 12px;
}
.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999;
}
.rc-slider-mark-text-active {
  color: #666;
}
.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}
.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}
.rc-slider-dot-active {
  border-color: #ffffff;
}
.rc-slider-disabled {
  background-color: #e9e9e9;
}
.rc-slider-disabled .rc-slider-track {
  background-color: #ccc;
}
.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
  border-color: #ccc;
  box-shadow: none;
  background-color: #fff;
  cursor: not-allowed;
}
.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
  cursor: not-allowed !important;
}
.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px;
}
.rc-slider-vertical .rc-slider-rail {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-track {
  left: 5px;
  bottom: 0;
  width: 4px;
}
.rc-slider-vertical .rc-slider-handle {
  margin-left: -5px;
  margin-bottom: -7px;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}
.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 18px;
  height: 100%;
}
.rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-dot {
  left: 2px;
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  display: block !important;
  animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-exit {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  display: block !important;
  animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  animation-name: rcSliderTooltipZoomDownIn;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-exit.rc-slider-tooltip-zoom-down-exit-active {
  animation-name: rcSliderTooltipZoomDownOut;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  transform: scale(0, 0);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.rc-slider-tooltip-zoom-down-exit {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
  100% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
}
@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
}
.rc-slider-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip-hidden {
  display: none;
}
.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px 0;
}
.rc-slider-tooltip-inner {
  padding: 6px 2px;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #6c6c6c;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9;
}
.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #6c6c6c;
}

.multi.selectContainer {
  text-align: left !important;
}

.multiple-who .selectLine {
  height: 50px !important;
  line-height: 50px !important;
}

.loader {
  position: absolute;
  top: 0;
  height: 10px;
  width: 100%;
  z-index: 100;
}

.flashcard {
  height: 100% !important;
}

.progressFlashcard {
  max-height: none !important;
  height: 30px;
  /* padding-left: 0 !important; */
  /* padding-right: 0 !important; */
}

.carousel-inner {
  height: 100% !important;
}

.react-card-flip {
  height: 100% !important;
}

.quiz-button:disabled {
  background: #6c6c6c !important;
}
